<template>
  <hcc-dropdown :custom="true" customClass="dropdown-position">
    <template #button>
      <div class="sidebar-profile wrapper-profilee">
        <div class="profile-photo">
          <span id="user_image" class="profile-acronym">{{
            profileAcronym
          }}</span>
        </div>
        <div>
          <div class="profile-name">{{ profileName }}</div>
          <div class="profile-role">{{userInfo.role}}</div>
        </div>
      </div>
    </template>
    <hcc-dropdown-item @click="logoutUser">
      {{ $t("header.user-menu.logout") }}
    </hcc-dropdown-item>
    <hcc-dropdown-item @click="openFAQ" customClass="menu-link">
      FAQ
    </hcc-dropdown-item>
  </hcc-dropdown>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import apolloProvider from '@/apollo';
import userChangeStatus from '@/graphql/mutations/userChangeStatus.gql';
import HccDropdown from '@/components/shared/HccDropdown/index.vue';
import HccDropdownItem from '@/components/shared/HccDropdown/DropdownItem.vue';
import { onLogout } from '@/vue-apollo';

export default {
  components: {
    HccDropdown,
    HccDropdownItem,
  },
  props: {
    profileName: {
      type: String,
    },
    profileAcronym: {
      type: String,
    },
  },
  computed: {
    ...mapGetters({
      userInfo: 'shared/userInfo',
    }),
    status() {
      return this.active ? 'STANDBY' : 'ONLINE';
    },
    active() {
      return this.userInfo.status === 'online';
    },
  },
  methods: {
    ...mapMutations({
      clearCompanies: 'shared/logout',
      setLogged: 'shared/setLogged',
    }),
    changeStatus(status) {
      return this.$apollo.mutate({
        mutation: userChangeStatus,
        variables: {
          id: this.userInfo.id,
          status,
        },
      });
    },
    async logoutUser() {
      this.setLogged(false);
      await this.changeStatus('OFFLINE');
      this.clearCompanies();
      onLogout(apolloProvider.defaultClient);
      window.location.href = process.env.VUE_APP_REDIRECT_URL;
    },
    openFAQ() {
      const url = process.env.VUE_APP_SELLIA_BLOG;
      window.open(`${url}/preguntas-frecuentes/`, '_blank', 'noopener, noreferrer');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~styles/components/_user_menu.scss";
</style>
